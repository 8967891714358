import { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';

const AppInstallPrompt = dynamic(() => import('./app-install-prompt'), {
  ssr: false,
});
const AppOpenPrompt = dynamic(() => import('./app-open-prompt'), {
  ssr: false,
});

function MobileDownloadBanner() {
  const [installed, setInstalled] = useState(false);
  // to enalbe the mobile download banner in Android, make undefined insted of false.
  const [enableMobilePrompt, setEnableMobilePrompt] = useState(false);

  useEffect(() => {
    const callGetInstalledApps = async () => {
      if (navigator?.userAgent?.includes('Android')) {
        let relatedApps = [];
        try {
          relatedApps = await (navigator as any)?.getInstalledRelatedApps();
        } catch (e) {
          console.log(e);
        }
        const relatedAppsFound = relatedApps.length > 0;
        if (relatedAppsFound) {
          setInstalled(true);
        }
        const promptAlreadyClosedByUser = JSON.parse(
          sessionStorage.getItem('installOrOpenPromptClosedByUser')
        );
        if (promptAlreadyClosedByUser === null) {
          document.body.classList.add('has-mobile-app-install-or-open-prompt');
          // to enable the mobile download banner in Android, make true instead of false.
          setEnableMobilePrompt(false);
        }
      }
    };
    callGetInstalledApps();
  }, []);

  useEffect(() => {
    if (typeof enableMobilePrompt === 'boolean' && !enableMobilePrompt) {
      window.sessionStorage.setItem('installOrOpenPromptClosedByUser', 'true');
    }
  }, [enableMobilePrompt]);

  return (
    <>
      {enableMobilePrompt && installed && (
        <AppOpenPrompt setEnableMobilePrompt={setEnableMobilePrompt} />
      )}
      {enableMobilePrompt && !installed && (
        <AppInstallPrompt setEnableMobilePrompt={setEnableMobilePrompt} />
      )}
    </>
  );
}

export default MobileDownloadBanner;
