import React from 'react';
import { ImageWrapper, PrismicLink } from '@ee-monorepo/prismic/shared/ui';
import cn from 'classnames';
import { FooterNavItem } from '../types';
import { useRouter } from 'next/router';
import { checkMatchingPathname } from '@ee-monorepo/prismic/shared/utilities';
import { useTranslations } from 'next-intl';

export interface DesktopFooterProps {
  footerNav: FooterNavItem[];
}

function DesktopFooter({ footerNav }: DesktopFooterProps) {
  const router = useRouter();
  const t = useTranslations();
  return (
    <div className="snap-row md:justify-around border-b mb-3">
      {footerNav.map((e, i) => (
        <div className="md:w-auto lg:w-1/6 py-8 px-1" key={i}>
          <p className="bodyHeavyFont text-SnapGrey500">{e.category}</p>
          <ul className="list-none pl-0 mb-0">
            {e.items.map((e, i) => (
              <li className="pb-3" key={i}>
                <PrismicLink
                  link={e.item_link}
                  className={cn(
                    'secondaryFont hover:font-bold active:font-bold hover:tracking-normal text-SnapBlue',
                    checkMatchingPathname(router, e.item_link)
                      ? 'secondaryHeavyFont'
                      : null
                  )}
                >
                  {e.item_label}
                </PrismicLink>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <div className="md:w-1/2 flex justify-end">
        <div className="lg:block hidden self-end">
          <ImageWrapper
            src="/assets/icons/footer/laptop_with_icons.svg"
            alt={t('shared.laptop_with_icons_alt')}
            width={379}
            height={279}
          />
        </div>
        <div className="block lg:hidden">
          <ImageWrapper
            src="/assets/icons/footer/phone_with_icons.svg"
            alt={t('shared.phone_with_icons_alt')}
            width={325}
            height={272}
          />
        </div>
      </div>
    </div>
  );
}

export default DesktopFooter;
