import { appConfig } from '@ee-monorepo/shared/utilities/constants';
// The current path names are just placeholders until the paths are created

export interface NavigationRoute {
  testId: string;
  navLinkName: string;
  path: string;
  newTab: boolean;
  isCustomerLink?: boolean;
}

const NavigationRoutes: NavigationRoute[] = [
  {
    testId: 'how-it-works-nav-option',
    navLinkName: 'how_it_works_nav_option',
    path: '/how-it-works',
    newTab: false,
    isCustomerLink: true
  },
  {
    testId: 'find-a-store-nav-option',
    navLinkName: 'find_a_store_nav_option',
    path: '/find-stores',
    newTab: false,
    isCustomerLink: true
  },
  {
    testId: 'business-nav-option',
    navLinkName: 'business_nav_option',
    path: '/partner',
    newTab: false,
    isCustomerLink: false
  },
  {
    testId: 'help-nav-option',
    navLinkName: 'help_nav_option',
    path: `${appConfig.supportDomainUrl}`,
    newTab: true,
  },
];

export default NavigationRoutes;
