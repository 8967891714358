import { ApplyContext } from '@ee-monorepo/shared/context/apply';
import { useFeatureFlags } from '@ee-monorepo/shared/data-access/experiments';
import {
  ApplyFunction,
  useApply,
  useCalculateRefHeight,
  useNavigationBarHeight,
} from '@ee-monorepo/shared/utilities/hooks';
import { Document } from 'prismic-javascript/types/documents';
import { ReactNode, useCallback, useEffect } from 'react';
import Footer from '../footer';
import MobileDownloadBanner from '../mobile-download-banner/mobile-download-banner';
import { Navigation } from '@ee-monorepo/navigation';
import { TrackJS } from '../../utils/trackjs-isomorphic';
import { useRouter } from 'next/router';
import { Client } from '@prismicio/client';
import { PrismicDocument } from '@prismicio/types';
import { SharedUiBanner } from '@ee-monorepo/prismic/shared/ui';
export interface ShellProps {
  children: ReactNode;
  layout?: Document;
  client?: Client<PrismicDocument<Record<string, any>, string, string>>;
  banner?: { uid: string };
}

const NavComponent = () => {
  try {
    return <Navigation />;
  } catch (error) {
    console.log('error in navigation:', error.message);
    TrackJS.track(`error in navigation: ${error.message}`);
  }
  return null;
};

const FooterComponent = ({ layout }) => {
  try {
    return <Footer layout={layout} />;
  } catch (error) {
    console.log('error in footer:', error.message);
    TrackJS.track(`error in footer: ${error.message}`);
  }
  return null;
};

export function Shell({ children, layout, banner }: ShellProps) {
  const { featureFlags } = useFeatureFlags({
    useUnResolvedValue: true,
  });
  const { push } = useRouter();

  const { apply } = useApply({
    testVariation: featureFlags,
  });

  const applyFn = useCallback<ApplyFunction>(
    (options) => {
      if (!options?.merchant) {
        push('/find-stores');
      } else {
        apply(options);
      }
    },
    [apply, push]
  );
  const { height, setIsReady, ref } = useCalculateRefHeight();
  const { setHeight } = useNavigationBarHeight();
  useEffect(() => {
    setHeight(height);
  }, [height]);

  return (
    <ApplyContext.Provider value={applyFn}>
      <div className={'pt-0 '}>
        <div style={{ paddingBottom: height }}></div>
        <div
          data-testid="top_nav"
          ref={ref}
          className="fixed top-0 w-full z-50"
        >
          <SharedUiBanner bannerUid={banner?.uid} isReady={setIsReady} />
          <NavComponent />
        </div>
        {children}
        <FooterComponent layout={layout} />
        <MobileDownloadBanner />
      </div>
    </ApplyContext.Provider>
  );
}
