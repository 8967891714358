import React from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { ListItem, ListItemText } from '@mui/material';

import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import s from './mobile-menu-item.module.scss';

interface MobileMenuItemProps {
  navLinkName: string;
  path: string;
  closeAll: () => void;
  testId: string;
  newTab: boolean;
}

function MobileMenuItem({
  navLinkName,
  path,
  closeAll,
  testId,
  newTab,
}: MobileMenuItemProps) {
  const t = useTranslations();
  const router = useRouter();

  return (
    <ListItem
      data-testid={testId}
      onClick={() => closeAll()}
      className={cn(s.listItem, 'my-2', s.navOption, {
        [s.active]: router.asPath === path,
      })}
    >
      <ListItemText primaryTypographyProps={{ variant: 'h4' }}>
        <Link
          href={path}
          target={newTab ? '_blank' : '_self'}
          className={s.anchor}
        >
          {t(`shared.${navLinkName}`)}
        </Link>
      </ListItemText>
    </ListItem>
  );
}

export default MobileMenuItem;
