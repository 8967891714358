const NavigationPosition = {
  SHOP_ARROW_OFFSET_POSITION: 275,
  SHOP_DROPDOWN_OFFSET_POSITION: 160,
  BUSINESS_ARROW_OFFSET_POSITION: 325,
  BUSINESS_DROPDOWN_OFFSET_POSITION: 180,
  SUPPORT_ARROW_OFFSET_POSITION: 383,
  SUPPORT_DROPDOWN_OFFSET_POSITION: 250,
};

export default NavigationPosition;
