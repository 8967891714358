import Link from 'next/link';
import { Link as MatLink } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import LanguageIcon from '@mui/icons-material/Language';

function LanguageToggle(props: { className?: any }) {
  const t = useTranslations();
  const { asPath, locale } = useRouter();

  return (
    <div className={props.className} data-testid="language-toggle">
      <Link
        href={asPath}
        locale={locale === 'en-US' ? 'es-US' : 'en-US'}
        data-testid="change-language-link"
      >
        <LanguageIcon fontSize="small" className="text-SnapDarkBlue" />
        <MatLink
          className="text-SnapDarkBlue bodyHeavyFont"
          component="button"
          underline="hover"
        >
          <span className="ml-1" data-testid="language-option">
            {locale === 'en-US'
              ? t(`shared.spanish_short`)
              : t(`shared.english_short`)}
          </span>
        </MatLink>
      </Link>
    </div>
  );
}

export default LanguageToggle;
