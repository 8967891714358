import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { AccordionSummaryDropdown } from '@ee-monorepo/shared/ui/accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PrismicLink, ImageWrapper } from '@ee-monorepo/prismic/shared/ui';
import { FooterNavItem } from '../types';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { checkMatchingPathname } from '@ee-monorepo/prismic/shared/utilities';
import { useTranslations } from 'next-intl';

export interface MobileFooterProps {
  footerNav: FooterNavItem[];
}

function MobileFooter({ footerNav }: MobileFooterProps) {
  const router = useRouter();
  const t = useTranslations();

  return (
    <>
      {footerNav.map((e, i) => (
        <Accordion key={i} elevation={0} className="static">
          <AccordionSummaryDropdown
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${i}content`}
            id={`panel${i}a-header`}
            className="min-h-[48px]"
          >
            <Typography className="bodyHeavyFont">{e.category}</Typography>
          </AccordionSummaryDropdown>
          {e.items.map((e, i) => (
            <AccordionDetails className="ml-5" key={i}>
              <PrismicLink
                link={e.item_link}
                className={cn(
                  'secondaryFont text-SnapBlue',
                  checkMatchingPathname(router, e.item_link)
                    ? 'secondaryHeavyFont'
                    : null
                )}
              >
                {e.item_label}
              </PrismicLink>
            </AccordionDetails>
          ))}
        </Accordion>
      ))}
      <div className="flex justify-center mt-5 mb-3 border-b">
        <ImageWrapper
          src="/assets/icons/footer/phone_with_icons.svg"
          alt={t('shared.phone_with_icons_alt')}
          width={325}
          height={272}
        />
      </div>
    </>
  );
}

export default MobileFooter;
