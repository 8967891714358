import { Button } from '@ee-monorepo/shared/ui/button';
import { KeyboardEvent, useState, useRef } from 'react';
import { Grow, Paper, Popper, MenuItem } from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import CustomMenuList from '../menu-list';
import { LoginArrowSlider } from './login-arrow-slider';
import LoginMenuItem from './login-menu-item';
import s from './login.module.scss';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';

export function Login() {
  const t = useTranslations();
  const [loginOpen, setLoginOpen] = useState(false);
  const loginAnchorRef = useRef(null);
  const { tracking } = useTrackingContext();

  const handleListKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Tab') {
      event.preventDefault();
      setLoginOpen(false);
    }
  };

  return (
    <div
      data-testid="login-nav-div"
      onMouseOver={() => {
        setLoginOpen(true);
      }}
      onMouseLeave={() => {
        setLoginOpen(false);
      }}
      className={cn(s.loginButtonWrapper)}
    >
      <Button
        data-testid="login-button"
        variant="tertiary"
        size="compact"
        className={cn(
          s.secondaryHeavyFont,
          s.navButton,
          s.leftSpace,
          s.loginButton,
          'flex',
          {
            [s.navFocus]: loginOpen,
          }
        )}
        ref={loginAnchorRef}
        aria-controls={loginOpen ? 'login' : undefined}
        aria-haspopup="true"
        onClick={() => setLoginOpen(!loginOpen)}
      >
        {t('shared.log_in')}
        <span className="ml-1">
          {loginOpen ? (
            <KeyboardArrowUpIcon fontSize="small" />
          ) : (
            <KeyboardArrowDownIcon fontSize="small" />
          )}
        </span>
      </Button>
      {loginOpen && <LoginArrowSlider className={s.loginArrowSlider} />}
      <Popper
        open={loginOpen}
        anchorEl={loginAnchorRef.current}
        placement="bottom-end"
        className={s.popperContainer}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}
          >
            <Paper>
              <CustomMenuList
                data-testid="dropdown-login"
                autoFocusItem={loginOpen}
                id="login"
                onKeyDown={handleListKeyDown}
                className={s.menuListContainer}
                autoFocus={true}
              >
                <MenuItem
                  data-testid="customer-login-option"
                  onClick={() => {
                    setLoginOpen(false);
                    tracking?.logClickEvent({
                      click_type: 'dropdown',
                      placement: 'top navigation',
                      click_value: 'customer login',
                    });
                    window.location.href = appConfig.customerPortalUrl;
                  }}
                  className={s.menuItemWithSubtextContainer}
                >
                  <LoginMenuItem
                    headingName="customer_login_header"
                    borderPosition="bottom"
                  />
                </MenuItem>
                <MenuItem
                  data-testid="business-login-option"
                  onClick={() => {
                    setLoginOpen(false);
                    tracking?.logClickEvent({
                      click_type: 'dropdown',
                      placement: 'top navigation',
                      click_value: 'business login',
                    });
                    window.location.href = appConfig.merchantPortalUrl;
                  }}
                  className={s.menuItemWithSubtextContainer}
                >
                  <LoginMenuItem headingName="business_login_header" />
                </MenuItem>
              </CustomMenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default Login;
