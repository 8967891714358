import React from 'react';
import { legalSerializer } from '@ee-monorepo/prismic/shared/serializers';
import * as pf from '@ee-monorepo/prismic/shared/types';
import { PrismicLink } from '@ee-monorepo/prismic/shared/ui';
import { PrismicImage } from '@prismicio/react';
import { useTablet } from '@ee-monorepo/shared/utilities/hooks';
import MobileFooter from './mobile/mobile-footer';
import DesktopFooter from './desktop/desktop-footer';
import { FooterNavItem, MenuItem } from './types';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { checkMatchingPathname } from '@ee-monorepo/prismic/shared/utilities';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';

const COOKIE_LINK = 'https:///snapfinance.com/cookie';
export interface FooterProps {
  layout: {
    data: {
      legal_pages: [
        { legal_page_name: pf.KeyTextField; legal_page_link: pf.Link }
      ];
      copyright: pf.KeyTextField;
      category_menu_1: pf.KeyTextField;
      category_1_items: [MenuItem];
      category_menu_2: pf.KeyTextField;
      category_2_items: [MenuItem];
      category_menu_3: pf.KeyTextField;
      category_3_items: [MenuItem];
      custom_media_links: [
        {
          media_link: pf.Link;
          custom_media_icon: pf.Image;
        }
      ];
      bottom_text: pf.RichTextField;
    };
  };
}

function Footer({ layout }: FooterProps) {
  const isMobile = useTablet();
  const router = useRouter();

  if (!layout) return null;
  const {
    legal_pages,
    copyright,
    category_menu_1,
    category_1_items,
    category_menu_2,
    category_2_items,
    category_menu_3,
    category_3_items,
    custom_media_links,
    bottom_text,
  } = layout.data;
  const footerNav: FooterNavItem[] = [
    {
      category: category_menu_1,
      items: category_1_items,
    },
    {
      category: category_menu_2,
      items: category_2_items,
    },
    {
      category: category_menu_3,
      items: category_3_items,
    },
  ];
  return (
    <footer
      id="footer"
      className={cn(
        'mt-12',
        'mb-16',
        'md:pb-0',
        'sm:pb-10',
        'snap-container-no-pad'
      )}
    >
      <div>
        {!isMobile ? (
          <DesktopFooter footerNav={footerNav} />
        ) : (
          <MobileFooter footerNav={footerNav} />
        )}
        <small className="flex flex-col items-center lg:flex-row lg:justify-between">
          <span className="[&>a]:secondaryHeavyFont [&>a]:pr-5 sm:py-2 grid md:grid-flow-col max-md:grid-cols-2 max-md:pl-4">
            {legal_pages &&
              legal_pages?.map((page) =>
                page?.legal_page_link?.url !== COOKIE_LINK ? (
                  <PrismicLink
                    link={page?.legal_page_link}
                    className={cn(
                      'text-SnapGrey400 hover:underline',
                      checkMatchingPathname(router, page?.legal_page_link)
                        ? 'underline'
                        : null
                    )}
                  >
                    {page?.legal_page_name}
                  </PrismicLink>
                ) : (
                  <a className="footer-item lb-preferences-center-trigger secondaryHeavyFont pr-5 text-SnapGrey400 hover:underline cursor-pointer">
                    {page?.legal_page_name}
                  </a>
                )
              )}
          </span>
          <span className="secondaryFont text-SnapGrey400 lg:absolute lg:mt-16">
            {copyright}
          </span>
          <span className="inline-flex pt-[8px]">
            {custom_media_links?.map(
              (e, i) =>
                prismicH.isFilled.link(e?.media_link) && (
                  <PrismicLink link={e.media_link} className="px-2" key={i}>
                    <PrismicImage
                      field={e.custom_media_icon}
                      width="18"
                      height="18"
                    />
                  </PrismicLink>
                )
            )}
          </span>
        </small>
      </div>
      {bottom_text && (
        <div className="snap-container text-SnapGrey400 [&_.bodyFont]:text-xs [&_.bodyFont]:leading-6 pt-6">
          <PrismicRichText field={bottom_text} components={legalSerializer} />
        </div>
      )}
    </footer>
  );
}

export default Footer;
