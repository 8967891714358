import React from 'react';
import Desktop from './desktop';
import Mobile from './mobile';

export function Navigation() {
  return (
    <div>
      <div className="hidden lg:block">
        <Desktop />
      </div>
      <div className="block lg:hidden">
        <Mobile />
      </div>
    </div>
  );
}

export default Navigation;
