import { SnapLogo } from '@one-snap/next';
import { setGlobalCSSVariable } from '@ee-monorepo/shared/utilities/functions';
import {
  useIsPartnerPage,
  useModernNavBarContext,
  usePageSEOProviderContext,
  useScrolledDown,
} from '@ee-monorepo/shared/utilities/hooks';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { NavigationRoutes } from '../constants';
import Login from '../login';
import DesktopMenuItem from './desktop-menu-item';
import s from './desktop.module.scss';
import { Button } from '@ee-monorepo/shared/ui/button';
import { ApplyButton } from '@ee-monorepo/shared/ui/apply-button';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
import { useMerchantLongFormContext } from '@ee-monorepo/shared/context/merchant-form';
import { useTranslations } from 'next-intl';
import { NavigationRoute } from '../constants/navigation-routes';
import LanguageToggle from '../language-toggle/language-toggle';
import { useLanguageToggleExperiment } from '@ee-monorepo/growth-experiments';

const setCSSCoords = (offSetLeft: number, underlineWidth: number) => {
  setGlobalCSSVariable('--desktop-nav-underline-x-offset', `${offSetLeft}px`);
  setGlobalCSSVariable('--desktop-nav-underline-width', `${underlineWidth}px`);
};

function Desktop() {
  const router = useRouter();
  const { modernNavBarEnabled } = useModernNavBarContext();
  const { scrolled } = useScrolledDown({ enabled: modernNavBarEnabled });
  const [isHover, setIsHover] = useState(false);
  const [currentActivePath, setCurrentActivePath] = useState<
    NavigationRoute | undefined
  >(undefined);
  const [offSetLeft, setOffSetLeft] = useState(0);
  const [activeOffSetLeft, setActiveOffSetLeft] = useState(0);
  const [underlineWidth, setUnderlineWidth] = useState(0);
  const [activeUnderlineWidth, setActiveUnderlineWidth] = useState(0);
  const showNavigation = router?.asPath.indexOf('jobs') === -1;
  const { isPartner } = useIsPartnerPage();
  const ButtonComponent = !isPartner ? ApplyButton : Button;
  const { pageSEO } = usePageSEOProviderContext();
  const { show_customer_links = true } = pageSEO;
  const { tracking } = useTrackingContext();
  const { setShow: setShowLongFormDialog } = useMerchantLongFormContext();
  const t = useTranslations();
  const { showLanguageToggle } = useLanguageToggleExperiment();

  // This values are comming from the SEO section of each landing page.
  // If it is 'Standard navigation' then we will show all the menu items as its in com
  // If it is 'Clean navigation' we are only showing the logo
  // If its 'CTA with text banner' then we are showing the logo, the text banner and cta button
  const isCleanNav = pageSEO.navigation_bar_type === 'Clean navigation';
  const isCtaNav = pageSEO.navigation_bar_type === 'CTA with text banner';
  const isStandardNav =
    !pageSEO.navigation_bar_type || //This make this the defaut in case we dont recieved a bar type
    pageSEO.navigation_bar_type === 'Standard navigation';

  const memoizedCallBack = useCallback((ref) => {
    setIsHover(true);
    setOffSetLeft(ref.current?.offsetLeft);
    setUnderlineWidth(ref.current?.offsetWidth);
    setActiveOffSetLeft(ref.current?.offsetLeft);
    setActiveUnderlineWidth(ref.current?.offsetWidth);
  }, []);

  const filteredNavigation = () =>
    NavigationRoutes.filter((navOption) => !navOption.isCustomerLink);

  const Navigation = show_customer_links
    ? NavigationRoutes
    : filteredNavigation();

  const navList = isStandardNav
    ? Navigation.map((navOption) => {
        const isActive =
          router.asPath.includes(navOption.path) ||
          (navOption.path === '/find-stores' &&
            router.asPath.includes('/online-stores')) ||
          (navOption.path === '/find-stores' &&
            router.asPath.includes('/states'));

        return (
          <DesktopMenuItem
            testId={navOption.testId}
            key={navOption.navLinkName}
            navLinkName={navOption.navLinkName}
            path={navOption.path}
            isActive={isActive}
            initActivation={memoizedCallBack}
            newTab={navOption.newTab}
            onMouseOver={(event) => {
              setIsHover(true);
              setOffSetLeft(event.target.offsetLeft);
              setUnderlineWidth(event.target.offsetWidth);
              setCSSCoords(offSetLeft, underlineWidth);
            }}
            onMouseLeave={() => {
              setIsHover(false);

              if (currentActivePath) {
                setCSSCoords(activeOffSetLeft, activeUnderlineWidth);
              }
            }}
          />
        );
      })
    : null;

  useEffect(() => {
    setCurrentActivePath(
      Navigation.find(
        (route) =>
          router.asPath.includes(route.path) ||
          (route.path === '/find-stores' &&
            router.asPath.includes('/online-stores'))
      )
    );
    setCSSCoords(offSetLeft, underlineWidth);
  }, [router, offSetLeft, underlineWidth, Navigation]);

  return (
    <div
      className={cn(
        'flex w-full py-[20px] px-[64px] transition duration-700 ease-in-out z-10',
        {
          'bg-SnapWhite': !modernNavBarEnabled || scrolled,
          'justify-center': !isCleanNav,
        }
      )}
    >
      <div className="snap-container flex justify-between items-center">
        <SnapLogo
          size="lg"
          onClick={() =>
            tracking?.logClickEvent({
              click_type: 'link',
              placement: 'top navigation',
              click_value: 'home',
            })
          }
        />
        {isCtaNav && (
          <a className="text-SnapDarkBlue text-xl font-bold">
            {pageSEO.navigation_text_banner}
          </a>
        )}
        {showNavigation && (
          <div className="flex items-center">
            {navList != null && (
              <div>
                <div className="relative secondaryHeavyFont">{navList}</div>
                <span
                  data-testid="nav-underline"
                  className={cn(s.underline, {
                    block: isHover || currentActivePath,
                  })}
                />
              </div>
            )}
            <div className="flex items-center">
              {!isCleanNav && (
                <ButtonComponent
                  onClick={() => {
                    if (!isPartner) {
                      tracking?.logClickEvent({
                        click_type: 'cta',
                        placement: 'top navigation',
                        click_value: 'get started',
                      });
                    } else {
                      tracking?.logClickEvent({
                        click_type: 'cta',
                        placement: 'top navigation',
                        click_value: 'get started',
                      });
                      setShowLongFormDialog(true);
                    }
                  }}
                  variant={isPartner ? 'secondary' : 'primary'}
                  data-testid={isPartner ? 'partner-button' : 'apply-button'}
                  size="compact"
                >
                  {isPartner ? t('shared.get_started') : t(`shared.apply_now`)}
                </ButtonComponent>
              )}
              {isStandardNav && <Login />}
              {showLanguageToggle && <LanguageToggle />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Desktop;
