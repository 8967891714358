import { useWindowResize } from '@ee-monorepo/shared/utilities/hooks';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useEffect, useRef } from 'react';
import s from './desktop-menu-item.module.scss';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';

interface DesktopMenuItemProps {
  navLinkName: string;
  path: string;
  onMouseOver: (event: any) => void;
  onMouseLeave: (event: any) => void;
  isActive: boolean;
  testId: string;
  initActivation: (ref: any) => void;
  newTab: boolean;
}

function DesktopMenuItem({
  navLinkName,
  path,
  onMouseOver,
  onMouseLeave,
  isActive,
  testId,
  initActivation,
  newTab,
}: DesktopMenuItemProps) {
  const t = useTranslations();
  const linkRef = useRef();
  const { width } = useWindowResize();
  const { tracking } = useTrackingContext();
  //This useEffect is used to position the indicator corrctly.
  //By adding timeout, it calculate values after render.
  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        initActivation(linkRef);
      }, 500);
    }
  }, [isActive, linkRef, initActivation]);

  //This useEffect is used to trigger the calculations when screen resizes.
  useEffect(() => {
    if (isActive) {
      initActivation(linkRef);
    }
  }, [isActive, linkRef, initActivation, width]);

  return (
    <Link
      href={path}
      target={newTab ? '_blank' : '_self'}
      data-testid={testId}
      ref={linkRef}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      className={cn(
        s.navLink,
        s.secondaryHeavyFont,
        isActive ? 'pointer-events-none' : null,
        {
          [s.active]: isActive,
        }
      )}
      onClick={() =>
        tracking?.logClickEvent({
          click_type: 'link',
          placement: 'top navigation',
          click_value: t(`shared.${navLinkName}`).toLowerCase(),
        })
      }
    >
      {t(`shared.${navLinkName}`)}
    </Link>
  );
}

export default DesktopMenuItem;
