import { AccordionSummary, styled } from '@mui/material';
import { colors } from '@snap/colors';

export const AccordionSummaryDropdown = styled(AccordionSummary)(
  ({ theme }) => ({
    '&.MuiButtonBase-root': {
      '&:hover': {
        '& .MuiAccordionSummary-content': {
          color: colors.SnapMediumBlue,
        },
        '& svg': {
          color: colors.SnapMediumBlue,
        },
      },
      '& .MuiAccordionSummary-content': {
        color: colors.SnapBlue,
        paddingTop: '12px',
        margin: 0,
        '@media (max-width: 765px)': {
          paddingTop: '4px',
        },
      },
      '& svg': {
        color: colors.SnapBlue,
      },
      '&.Mui-expanded': {
        minHeight: 0,
        '& .MuiAccordionSummary-content': {
          color: colors.SnapDarkBlue,
          marginBottom: 0,
        },
        '& svg': {
          color: colors.SnapDarkBlue,
        },
      },
    },
  })
);
