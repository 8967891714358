import { useApplyContext } from '@ee-monorepo/shared/context/apply';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
import { Button } from '@ee-monorepo/shared/ui/button';
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { Close as CloseIcon, Menu as MenuIcon } from '@mui/icons-material';
import { IconButton, List, Drawer, Box } from '@mui/material';
import { useTranslations } from 'next-intl';
import MobileMenuItem from './mobile-menu-item';
import { setGlobalCSSVariable } from '@ee-monorepo/shared/utilities/functions';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import { SnapLogo } from '@one-snap/next';
import {
  useIsPartnerPage,
  useIsStoreLocator,
  useModernNavBarContext,
  usePageSEOProviderContext,
  useScrolledDown,
} from '@ee-monorepo/shared/utilities/hooks';
import { useMerchantLongFormContext } from '@ee-monorepo/shared/context/merchant-form';
import { NavigationRoutes } from '../constants';
import s from './mobile.module.scss';
import { ApplyButton } from '@ee-monorepo/shared/ui/apply-button';
import LanguageToggle from '../language-toggle/language-toggle';
import { useLanguageToggleExperiment } from '@ee-monorepo/growth-experiments';

function Mobile() {
  const { modernNavBarEnabled } = useModernNavBarContext();
  const { scrolled } = useScrolledDown({ enabled: modernNavBarEnabled });
  const { apply } = useApplyContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoginOptionsOpen, setIsLoginOptionsOpen] = useState(false);
  const { isPartner } = useIsPartnerPage();
  const { isStoreLocator } = useIsStoreLocator();
  const hideApplyButton = isStoreLocator;
  const router = useRouter();
  const { setShow: setShowLongFormDialog } = useMerchantLongFormContext();
  const { tracking } = useTrackingContext();
  const { pageSEO } = usePageSEOProviderContext();
  const ButtonComponent = !isPartner ? ApplyButton : Button;
  const { showLanguageToggle } = useLanguageToggleExperiment();

  useEffect(() => {
    const navContainerHeight = 72;
    const navMenuHeight = isMenuOpen
      ? `calc(${window.innerHeight}px - ${navContainerHeight}px)`
      : '0px';
    setGlobalCSSVariable('--mobile-nav-menu-height', navMenuHeight);
    setGlobalCSSVariable(
      '--mobile-nav-container-height',
      `${navContainerHeight}px`
    );
  }, [isMenuOpen]);

  const closeAll = () => {
    setIsLoginOptionsOpen(false);
    setIsMenuOpen(false);
    document.body.classList.remove('mobile-menu-expanded');
  };

  const t = useTranslations();

  const isStandardNav =
    !pageSEO.navigation_bar_type || //This make this the defaut in case we dont recieved a bar type
    pageSEO.navigation_bar_type === 'Standard navigation';
  const menuList = isStandardNav
    ? NavigationRoutes.map((navOption) => (
        <MobileMenuItem
          newTab={navOption.newTab}
          testId={navOption.testId}
          key={navOption.navLinkName}
          navLinkName={navOption.navLinkName}
          path={navOption.path}
          closeAll={closeAll}
        />
      ))
    : null;

  return (
    <div>
      <div
        className={cn(s.navContainer, 'flex', {
          'bg-SnapWhite': !modernNavBarEnabled || scrolled,
        })}
      >
        <SnapLogo
          onClick={() => {
            closeAll();
            tracking?.logClickEvent({
              click_type: 'link',
              placement: 'top navigation',
              click_value: 'home',
            });
          }}
          size="sm"
        />
        <div className={cn('flex')}>
          {showLanguageToggle && <LanguageToggle className={cn('mt-3')} />}
          {pageSEO.navigation_bar_type === 'CTA with text banner' && (
            <ButtonComponent
              onClick={() => {
                if (!isPartner) {
                  tracking?.logClickEvent({
                    click_type: 'cta',
                    placement: 'top navigation',
                    click_value: 'apply now',
                  });
                  apply();
                } else {
                  tracking?.logClickEvent({
                    click_type: 'cta',
                    placement: 'top navigation',
                    click_value: 'apply now',
                  });
                  setShowLongFormDialog(true);
                }
              }}
              variant={isPartner ? 'secondary' : 'primary'}
              data-testid={isPartner ? 'partner-button' : 'apply-button'}
              size="compact"
              className={s.button}
            >
              {isPartner ? t('shared.get_started') : t(`shared.apply_now`)}
            </ButtonComponent>
          )}
          {router.pathname.indexOf('jobs') === -1 && menuList != null && (
            <IconButton
              data-testid="menu-button"
              aria-haspopup="true"
              aria-label="menu-button"
              aria-expanded={isMenuOpen}
              onClick={() => {
                document.body.classList.add('mobile-menu-expanded');
                setIsMenuOpen(!isMenuOpen);
                setIsLoginOptionsOpen(false);
              }}
              className="pr-0"
            >
              <MenuIcon className={s.mobileButtonColor} fontSize="large" />
            </IconButton>
          )}
        </div>
      </div>
      <Drawer
        variant="persistent"
        open={isMenuOpen}
        anchor="top"
        classes={{ paper: 'h-full' }}
      >
        <Box className={cn(s.drawerContainer, 'flex')}>
          <SnapLogo
            data-testid="drawerLogo"
            onClick={() => closeAll()}
            size="sm"
          />
          <IconButton
            data-testid="close-button"
            aria-haspopup="true"
            aria-expanded={isMenuOpen}
            onClick={() => {
              document.body.classList.remove('mobile-menu-expanded');
              setIsMenuOpen(!isMenuOpen);
              setIsLoginOptionsOpen(false);
            }}
            className="pr-0"
          >
            <CloseIcon className={s.mobileButtonColor} fontSize="large" />
          </IconButton>
        </Box>
        <List
          className="h-full"
          data-testid={isMenuOpen ? 'menu-visible' : 'menu-hidden'}
        >
          {menuList}
        </List>
        <div
          className={cn(s.footer, 'flex', {
            'bg-SnapSand': !isPartner,
            'bg-SnapGrey100': isPartner,
          })}
        >
          {isLoginOptionsOpen ? (
            <div className={cn('flex', s.loginContainer)}>
              <Button
                variant="secondary"
                className={'mb-3'}
                onClick={() => {
                  closeAll();
                  window.location.href = appConfig.customerPortalUrl;
                }}
                data-testid="customer-login-button"
              >
                {t(`shared.customer_log_in`)}
              </Button>
              <Button
                variant="secondary"
                className={'mb-3'}
                onClick={() => {
                  closeAll();
                  window.location.href = appConfig.merchantPortalUrl;
                }}
                data-testid="business-login-button"
              >
                {t(`shared.business_log_in`)}
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => setIsLoginOptionsOpen(true)}
              data-testid="login-button"
              variant="secondary"
            >
              {t(`shared.log_in`)}
            </Button>
          )}

          <Button
            onClick={() => {
              closeAll();
              if (!isPartner) {
                apply();
              } else {
                closeAll();
                setShowLongFormDialog(true);
                tracking?.logClickEvent({
                  click_type: 'cta',
                  placement: 'top navigation',
                  click_value: 'get started',
                });
              }
            }}
            data-testid={isPartner ? 'partner-button' : 'apply-button'}
            className={cn('mt-3', { hidden: hideApplyButton })}
          >
            {isPartner ? t(`shared.get_started`) : t(`shared.apply_now`)}
          </Button>
        </div>
      </Drawer>
    </div>
  );
}

export default Mobile;
