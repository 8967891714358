import { Accordion, styled } from '@mui/material';
import { colors } from '@snap/colors';

export const AccordionDropdown = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  '&.Mui-expanded': {
    marginTop: 0,
  },
  '&:before': {
    backgroundColor: colors.SnapWhite,
  },
  '&.Mui-expanded:before': {
    opacity: 1,
  },
  '& .MuiAccordionSummary-root': {
    padding: 0,
  },
  '& .MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 'inherit',
  },
  '& .MuiAccordionSummary-content': {
    margin: '16px 0',
    transition:
      'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, font-weight 150ms ease',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    fontWeight: '700',
  },
  '& .MuiAccordionDetails-root': {
    paddingTop: 0,
  },
}));
