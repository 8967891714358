export function LoginArrowSlider(props) {
  const { className } = props;
  return (
    <svg
      width="24"
      height="8"
      viewBox="0 0 24 8"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      data-testid="login-arrow-slider"
    >
      <path
        d="M11.4453 0.369801C11.7812 0.145868 12.2188 0.145868 12.5547 0.369801L24 8L-6.99382e-07 8L11.4453 0.369801Z"
        fill="white"
      />
    </svg>
  );
}
