import React from 'react';
import cn from 'classnames';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTranslations } from 'next-intl';
import s from './login-menu-item.module.scss';
interface MenuItemSubTextProps {
  headingName: string;
  borderPosition?: string;
}

function LoginMenuItem({ headingName, borderPosition }: MenuItemSubTextProps) {
  const t = useTranslations();

  return (
    <div
      className={cn(s.container, 'flex', {
        [s[borderPosition]]: borderPosition,
      })}
    >
      <h4 className={s.header}>{t(`shared.${headingName}`)}</h4>
      <div className={s.arrowIcon}>
        <KeyboardArrowRight />
      </div>
    </div>
  );
}

export default LoginMenuItem;
